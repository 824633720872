<template>
  <div class="block-row mb-5">
    <!-- Header -->
    <v-row class="d-flex justify-end">
      <v-col cols="6">
        <h4>Blocco di testo</h4>
      </v-col>
      <v-spacer></v-spacer>
      <v-btn-toggle dense style="background-color: transparent">
        <v-btn class="delete-btn" v-if="deletable" @click="confirmDelete">
          <i class="flaticon2-rubbish-bin"></i>
        </v-btn>
      </v-btn-toggle>
    </v-row>

    <v-divider></v-divider>
    <v-row>
      <v-col cols="12">
        <b>{{ label }}</b>
        <br />
        <ckeditor
          :config="editorConfig"
          :editor="editor"
          v-model="textBlock.content.text"
          @ready="onReady"
          @input="onChange"
        ></ckeditor>
      </v-col>
    </v-row>
  </div>
</template>


<script>
import { mapActions } from "vuex";
import {
  UPDATE_TEXT_BLOCK,
  DELETE_TEXT_BLOCK,
} from "@/core/services/store/text_blocks.module";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import "@ckeditor/ckeditor5-build-decoupled-document/build/translations/it";

export default {
  components: {
    ckeditor: CKEditor.component,
  },

  props: {
    textBlock: {
      type: Object,
      default: function () {
        return {};
      },
    },

    label: {
      type: String,
      default: "",
    },

    deletable: {
      type: Boolean,
    },
  },

  data() {
    return {
      block: null,
      editor: DecoupledEditor,
      editorConfig: {
        language: "it",
        addMainLanguageTranslationsToAllAssets: true,
        toolbar: {
          items: [
            "heading",
            "|",
            "bold",
            "italic",
            "underline",
            "|",
            "alignment",
          ],
        },
      },
      editorChanged: false,
      editorChangeEventTimer: null,
    };
  },

  methods: {
    ...mapActions("snackbar", ["showSnack"]),

    onChange() {
      clearTimeout(this.editorChangeEventTimer);

      var params = {
        block: {
          text: this.textBlock.content.text,
        },
      };
      let id = this.textBlock.id;

      this.editorChangeEventTimer = setTimeout(() => {
        this.$store
          .dispatch(UPDATE_TEXT_BLOCK, { id: id, params: params })
          .then(() => {
            this.showSnack({
              text: "Blocco di testo aggiornato con successo.",
              color: "success",
              timeout: 3500,
            });

            this.$emit("change");
          });
      }, 2000);
    },

    created() {
      this.block = this.textBlock;
    },

    confirmDelete() {
      confirm(
        "Sicuro di voler eliminare questo elemento?\nL'operazione è irreversibile."
      ) && this.delete();
    },

    delete() {
      this.$store.dispatch(DELETE_TEXT_BLOCK, this.textBlock.id).then(() => {
        this.showSnack({
          text: "Blocco di testo eliminato con successo.",
          color: "success",
          timeout: 3500,
        });

        this.$emit("change");
      });
    },

    onReady(editor) {
      // Insert the toolbar before the editable area.
      editor.ui
        .getEditableElement()
        .parentElement.insertBefore(
          editor.ui.view.toolbar.element,
          editor.ui.getEditableElement()
        );
    },
  },
};
</script>
