<template>
  <div data-app v-if="page.id">
    <v-row>
      <v-col md="9" offset-xl="2" xl="6">
        <div class="card card-custom card-stretch">
          <!--begin::Body-->
          <div class="card-body">
            <v-row>
              <v-col md="12" xl="6">
                <v-text-field
                  label="Titolo pagina"
                  v-model="page.page_title"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="12" xl="6">
                <v-text-field
                  label="Titolo"
                  v-model="page.title"
                ></v-text-field>
              </v-col>
            </v-row>

            <div
              v-for="(block, index) in page.blocks"
              :key="block.id"
              :data-index="index"
            >
              <TextBlock
                v-if="block.block_type == 'TextBlock'"
                :textBlock="block"
                :deletable="false"
                label="Descrizione"
              />
            </div>

            <v-row class="mt-5">
              <v-col class="d-flex justify-end mt-5">
                <v-btn color="primary" dark class="mb-2" @click="submit()">
                  Salva pagina
                </v-btn>
              </v-col>
            </v-row>
          </div>
          <!--end::Body-->
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME,
} from "@/core/services/store/htmlclass.module";
import { TERM_DETAIL } from "@/core/services/store/terms_conditions.module";
import { UPDATE_PAGES } from "@/core/services/store/pages.module";
import { UPDATE_BLOCK } from "@/core/services/store/blocks.module";

import { mapActions } from "vuex";
import TextBlock from "@/view/components/Block/TextBlock.vue";
export default {
  components: {
    TextBlock,
  },

  data: () => ({
    page: {},
  }),

  mounted() {
    this.fetchTerms();
  },

  methods: {
    ...mapActions("snackbar", ["showSnack"]),

    submit() {
      this.$store
        .dispatch(UPDATE_PAGES, {
          id: this.page.id,
          data: {
            page_title: this.page.page_title,
            title: this.page.title,
          },
        })
        .then(async () => {
          this.page.blocks.forEach((block) => {
            var dataBlock = {};

            if (block.block_type == "TextBlock")
              dataBlock["text"] = this.page.blocks[0].content.text;

            this.$store.dispatch(UPDATE_BLOCK, {
              id: block.id,
              data: {
                block: dataBlock,
              },
            });
          });
        })
        .then(() => {
          this.fetchTerms();
          this.showSnack({
            text: "Pagina Termini e condizioni salvata con successo.",
            color: "success",
            timeout: 3500,
          });
        })
        .catch((error) => {
          alert(error.message);
        });
    },

    fetchTerms() {
      this.$store.dispatch(TERM_DETAIL).then((data) => {
        if (data.page != null) {
          this.page = data.page;
        }
      });
    },
  },

  watch: {
    loading(newVal) {
      if (newVal) {
        this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
      } else {
        this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
      }
    },
  },
};
</script>
